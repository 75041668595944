import React from 'react';
import objects from '../assets/Objects.svg';

const VersatileAPISupport = () => {
  return (
    <section className="pb-lg-5 mt-5">
      <div className='d-flex gap-lg-5 gap-2 flex-row row align-items-center justify-content-center my-5'>
        <img className="col-lg-6 col-md-12 images mt-5" src={objects} alt="objects" />
        <div className="col-lg-6 col-md-12 images align-items-center pt-lg-5 ps-lg-5 ms-lg-5">
          <div className='btn mb-lg-3 ps-4 pe-4 mb-3' style={{ backgroundColor: '#3E4E47', color: '#1AB272' }}>API Support</div>
          <h2>Versatile API Support</h2>
          <ul className={'font-size mt-3'}>
            <li>Extensive API support for developers</li>
            <li>Flexibility in system interaction</li>
            <li>Web-based interface accessibility</li>
            <li>Integration with JupyterLab</li>
            <li>Compatibility with SSH protocol</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default VersatileAPISupport;
