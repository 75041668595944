import React, { useState, useEffect } from 'react';
import '../Header.css';
import logo from '../assets/PiovationLogo.svg';
import forward from '../assets/ForwardArrow.svg';
import layer from '../assets/Layer1.svg';
import navbarLogo from '../assets/NavbarIcon.svg'

const Header = () => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);
  const [currentLineIndex, setCurrentLineIndex] = useState(0);
  const lines = ['Cloud Management', 'Model Training', 'Model Deployment'];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentLineIndex((prevIndex) => (prevIndex + 1) % lines.length);
    }, 1400);

    return () => clearInterval(interval);
  }, [lines.length]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  return (
    <header className="header">
      <nav className={`navbar navbar-expand-lg shadow-none py-0 ${isScrolled ? 'scrolled' : ''}`}>
        <div className="container justify-content-between align-items-center py-3">
          <a className="navbar-brand app-brand d-flex align-items-center py-2 me-lg-4 me-2 ps-lg-2" href="#">
            <img src={logo} alt="PioVation logo" className="app-brand-logo" />
          </a>
          <button
            className="navbar-toggler p-0"
            type="button"
            onClick={handleNavCollapse}
            aria-controls="navbarSupportedContent"
            aria-expanded={!isNavCollapsed}
            aria-label="Toggle navigation"
          >
            <img className='ms-2' src={navbarLogo}></img>
          </button>
          <div className={`navbar-collapse ${isNavCollapsed ? 'collapse' : ''}`} id="navbarSupportedContent">
            <ul className="navbar-nav m-auto gap-lg-5">
              <li className="nav-item">
                <a className="nav-link fw-medium text-light" href="#why-us">Why Us</a>
              </li>
              <li className="nav-item">
                <a className="nav-link fw-medium text-light" href="#features">Features</a>
              </li>
              <li className="nav-item">
                <a className="nav-link fw-medium text-light" href="#about-us">About Us</a>
              </li>
            </ul>
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link fw-medium text-light login-margin" href="#">Login</a>
              </li>
            </ul>
          </div>
          <button className="btn me-lg-2 ps-3 pe-3" style={{ backgroundColor: '#1BAE70', color: 'white' }}>
            Get Started <img className="ps-1 pb-1" src={forward} alt="forward arrow" />
          </button>
        </div>
      </nav>
      <div className="bg-image pt-5">
        <div className='content-container d-lg-flex gap-lg-5 flex-row align-items-center justify-content-center ps-lg-2 ps-lg-5'>
          <div className="col-lg-6 col-md-12 request-demo align-items-center">
            <h1 className='header-text'>Composable AI & HPC</h1>
            <h1 className='header-text'style={{ color: '#1BAE70' }}>{lines[currentLineIndex]}</h1>
            <h1 className='header-text'>Solution</h1>
            <button className="btn mt-3 pe-4 ps-4" style={{ backgroundColor: '#1BAE70', color: 'white' }}>
              Request Demo <img src={forward} alt="forward arrow" />
            </button>
          </div>
          <div className="col-lg-6 d-none d-lg-block d-xl-block  align-items-center">
            <img className="align-items-center layers-image me-2" src={layer} alt="Layer" />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
