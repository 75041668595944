import React from 'react';
import '../Footer.css';
import twitter from '../assets/Twitter.svg';
import linkedIn from '../assets/LinkedIn.svg';
import phone from '../assets/Phone.svg';
import email from '../assets/Email.svg'

const Footer = () => {
  return (
    <footer className="footer py-5">
      <div className="row ps-lg-5 ps-4">
        <div className="col-lg-2 col-md-3 col-4 mb-4 p-0 mt-3">
          <strong>OGC</strong>
          <ul className="list-unstyled pt-2">
            <li className='small pb-2'><a href="#" style={{textDecoration: 'none', color: 'white'}}>Our Services</a></li>
            <li className='small pb-2'><a href="#" style={{textDecoration: 'none', color: 'white'}}>Price Trends</a></li>
            <li className='small pb-2'><a href="#" style={{textDecoration: 'none', color: 'white'}}>Our Partners</a></li>
            <li className='small pb-2'><a href="#" style={{textDecoration: 'none', color: 'white'}}>Articles</a></li>
          </ul>
        </div>
        <div className="col-lg-2 col-md-3 col-4 mb-4 p-0 mt-3">
          <strong>About</strong>
          <ul className="list-unstyled pt-2">
            <li className='small pb-2'><a href="#about-us" style={{textDecoration: 'none', color: 'white'}}>About Us</a></li>
            <li className='small pb-2'><a href="#" style={{textDecoration: 'none', color: 'white'}}>Contact Us</a></li>
            <li className='small pb-2'><a href="#" style={{textDecoration: 'none', color: 'white'}}>Testimonials</a></li>
            <li className='small pb-2'><a href="#" style={{textDecoration: 'none', color: 'white'}}>Terms of Use</a></li>
          </ul>
        </div>
        <div className="col-lg-2 col-md-3 col-4 mb-4 pe-lg-1 p-0 mt-3">
          <strong>Get in Touch</strong>
          <ul className="list-unstyled pt-2">
            <li className='small pb-2'><a href="#" style={{textDecoration: 'none', color: 'white'}}><img className='me-2' src={twitter} ></img>Twitter</a></li>
            <li className='small pb-2'><a href="#" style={{textDecoration: 'none', color: 'white'}}><img className='me-2' src={linkedIn} ></img>LinkedIn</a></li>
            <li className='small pb-2'><a href="mailto:info@piovation.com" style={{textDecoration: 'none', color: 'white'}}><img className='me-2' src={email} ></img>info@piovation.com</a></li>
            <li className='small pb-2'><a href="tel:+4915208741767"><img className='me-2' src={phone} ></img>+49 1520 8741767</a></li>
          </ul>
        </div>
        <div className="col-lg-6 col-md-12 col-12 mt-2 p-0 text-center align-items-center">
          <h2 >Sign Up for the Newsletter</h2>
          <p className='subscribe-text'>Subscribe to our newsletter for new products and updates.</p>
          <form className="d-flex m-auto subscribe-form" style={{backgroundColor: 'white', borderRadius: '6px'}}>
            <input type="email" className="form-control border-0" placeholder="Enter your email" />
            <button type="submit" className="btn w-50" style={{ backgroundColor: '#1BAE70', color: 'white', margin: '2px' }}>Subscribe</button>
          </form>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
